import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'

const NotAllowedPage = () => {

  return (
    <Layout>
      <Seo title="401: Not allowed" />
      <Helmet bodyAttributes={{ class: `page error 401` }} />
      <article>
        <section className={`cc mt10`}>
          <div className={`wrapper flex flex-column`}>
            <h1>401: Not Allowed</h1>
            <p>You just hit a route that you&#39;re not allowed to access... the sadness.</p>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default NotAllowedPage
